.Container {
  position: fixed;

  overflow-y: auto;

  width: 100vw;
  height: 100vh;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #00000050;

  display: flex;
  align-items: center;
  justify-content: center;

}

.Content {
  position: relative;

  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 1px 6px 12px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  gap: 8px;

  margin: auto;
}

.Title {
  color: "#F6F6F6";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.083px;
}

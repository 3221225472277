.main-container {
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #F3125B;
  color: #fff;
}

button {
  border: none;
  border-radius: 10px;

  cursor: pointer;

  height: 50px;
  padding: 20px;

  color: #323031;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
}
.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loadingContainer {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.imageContainer {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 48px;
}
